




























































































































































import { Component, Vue } from "vue-property-decorator";
import RulePop from "@/views/supreme-list/RulePop.vue";
import BlindBox from "@/api/blind-box.api";
@Component({
  components: {
    RulePop,
  },
})
export default class SupremeList extends Vue {
  visiable = false;
  RankListData: any = [];
  ForList = [];
  close() {
    this.visiable = false;
  }
  NumberList = [
    "https://cdn.vgn.cn/static/new/img/1/zzb/F/4_2x.webp",
    "https://cdn.vgn.cn/static/new/img/1/zzb/F/5_2x.webp",
    "https://cdn.vgn.cn/static/new/img/1/zzb/F/6_2x.webp",
    "https://cdn.vgn.cn/static/new/img/1/zzb/F/7_2x.webp",
    "https://cdn.vgn.cn/static/new/img/1/zzb/F/8_2x.webp",
    "https://cdn.vgn.cn/static/new/img/1/zzb/F/9_2x.webp",
    "https://cdn.vgn.cn/static/new/img/1/zzb/F/10_2x.webp",
  ];
  async created() {
    this.RankListData = await BlindBox.getRankList();
    this.ForList = JSON.parse(JSON.stringify(this.RankListData.splice(3)));
  }
}
