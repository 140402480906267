







































































import { Component, Emit, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class RulePop extends Vue {
  @Emit()
  close() {}
}
